<template>
    <div v-if="post.title !== null " class="my-10">

        <div class="bg-white shadow rounded-lg mt-5">
            <div tag="div" :to="`/${post.creator.username}`" class="border-b border-gray-200 px-4 py-5 sm:px-6">
                <div tag="div" :to="`/${post.creator.username}`" class="flex justify-between items-center">
                    <div class="hover:bg-cool-gray-200 rounded-md p-2 flex items-center">
                        <router-link tag="div" :to="`/${post.creator.username}`" class="cursor-pointer flex items-center space-x-3">
<!--                            <img class="inline-block h-10 fa-w-10  rounded-full mr-2" :src="post.creator.avatarUrl" alt="">-->
                            <profile-image-with-pop-card :prop-user="post.creator" size="md"></profile-image-with-pop-card>
                            <span class="text-black">
                            {{ post.creator.firstName + " " + post.creator.lastName}}
                        </span>
                            <span class="text-cool-gray-500 text-sm ml-1">@{{post.creator.username}}</span>
                        </router-link>
                        <!--                        <user-image-tooltip :user="post.creator" size="lg"/>-->
                    </div>
                    <div class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 text-gray-500">
                        {{ timeAgo(post.createdAt) }}
                    </div>
                </div>

                <div class=" justify-between items-center">
                    <div class="p-2">
                        <h1 v-if="!isEditingPost" class="text-3xl leading-6 font-medium text-gray-900">
                            {{ post.title }}
                        </h1>
                        <label v-if="isEditingPost">
                            <input v-model="newPostTitle" type="text" class="block border-gray-400 rounded-md focus:ring-indigo-500 focus:border-indigo-500 border w-full rounded-md transition duration-150 ease-in-out sm:text-sm sm:leading-5">
                        </label>
                    </div>
                </div>

                <span v-if="post.commentsCount > 0" class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium leading-4 bg-green-100 text-green-800 capitalize mt-3">
                          {{numberOfComments}}
                </span>

            </div>
            <div class="px-4 py-5 sm:p-6">
                <div>
                    <jitap-clean-editor v-if="!isEditingPost" :content="post.content"/>
                    <main-editor-component :should-focus="true" v-else :content="post.content" @save="savePostEdit" @cancel="cancelPostEdit"/>
                </div>
            </div>

            <div class="px-4 py-5 sm:p-6 flex items-center flex justify-between">
                <div class="flex">
                    <button v-if="canEdit" @click="editClicked" class="text-cool-gray-500 flex items-center py-1 px-2 rounded-md hover:bg-purple-200">
                        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"/></svg>
                        <span class="ml-1 text-sm">Edit</span>
                    </button>

                    <button :data-clipboard-text="postLink" class="copyToClipBoard text-cool-gray-500 flex items-center py-1 px-2 rounded-md hover:bg-purple-200 ml-1">
                        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"/>
                        </svg>
                        <span class="ml-1 text-sm">Share</span>
                    </button>

                    <button @click="saveClicked" class="text-cool-gray-500 flex items-center py-1 px-2 rounded-md hover:bg-purple-200 ml-1" :class="{'text-jiruto-zotSecondary': isPostSaved}">
                        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"/>
                        </svg>
                        <span class="ml-1 text-sm">{{isPostSaved ? "Saved" : "Save"}}</span>
                    </button>
                </div>


                <div>
                    <button v-if="canEdit" @click="deleteClicked" class="text-cool-gray-500 flex items-center py-1 px-2 rounded-md hover:bg-red-500 hover:text-white ml-1">
                        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                        </svg>
                        <span class="ml-1 text-sm">Delete</span>
                    </button>
                </div>
            </div>

            <div class="border-t border-gray-200 px-4 py-4 sm:px-6">
                <main-editor-component ref="jitapMainCommentEditor" :comment-reply="true"  @save=commentPressed @cancel="cancelCommentEdit"/>
            </div>
        </div>

        <div>
            <transition-group name="comments" tag="div"
                              enter-class="transform opacity-0 scale-y-95"
                              enter-active-class="transition ease-out duration-700"
                              enter-to-class="transform opacity-100 scale-y-100"
                              leave-class="transform opacity-100 scale-y-100"
                              leave-active-class="transition ease-in duration-700"
                              leave-to-class="transform opacity-0 scale-y-95"
            >
                <div  v-for="(comment) in post.comments" :key="comment.JID">
                    <div :id="`comment-${comment.JID}`">
                        <div>
                            <forum-comment :forumID="forumProp.JID" :communityOwner="communityOwner" :communityName="communityName" :postID="postID" :comment-prop="comment" @deleteComment="deleteComment"/>
                        </div>
                    </div>
                </div>
            </transition-group>
        </div>


        <alert-modal :open="showDeletePostModal" v-on:close="cancelDeletePost" v-on:reset="deletePost">
            <template v-slot:header>
                You're about to delete this post!
            </template>
            <template v-slot:text>
                You've requested to delete this post. There's no undoing this. Please confirm.
            </template>
            <template v-slot:button>
                Delete
            </template>
            <template v-slot:cancelButton>
                Cancel
            </template>
        </alert-modal>

        <successNotification  :open="showSuccessLinkCopy" class="z-100" style="z-index: 100">
            <template v-slot:header>
                Link Copied!
            </template>
            <template v-slot:text>
                Post link copied to your clipboard successfully.
            </template>
        </successNotification>

    </div>
</template>

<script>
    import {timeAgo} from "@/utils/helpers";
    import {mapGetters} from 'vuex'
    import clipboard from 'clipboard'
    import * as _ from 'lodash'
    import {jigo} from '../../../axios-auth'
    import JitapCleanEditor from "../../../components/JitapCleanEditor";
    import ForumComment from "./ForumComment";
    import AlertModal from "../../../components/AlertModal";
    import MainEditorComponent from "../../../components/MainEditorComponent";
    import SuccessNotification from "../../../components/ActionNotification";
    import ProfileImageWithPopCard from "@/components/ProfileImageWithPopCard";

    export default {
        name: "ForumPost",
        props: ['communityOwner', 'communityName', 'forumProp', 'postID', ],
        components: {
            ProfileImageWithPopCard,
            SuccessNotification,
            MainEditorComponent,
            AlertModal,
            ForumComment,
            JitapCleanEditor,
        },
        data() {
            return {
                clipboard: new clipboard('.copyToClipBoard'),
                post: {
                    content: null,
                    lastUpdated: null,
                    creator: null,
                    title: null,
                    commentsCount: null,
                },
                newPostTitle: "",
                isEditingPost: false,
                isEditingComment: false,
                showAlertModal: false,
                scrollTo: null,
                showDeletePostModal: false,
                isPostSaved: false,
                showSuccessLinkCopy: false,
            }
        },
        created(){
            if(this.forumProp !== null) {
                this.fetchPost()
                this.fetchSavedCommentsIDs()
                this.fetchPostSaveStatus()
            }
            this.clipboard.on('success', () => {
                this.linkCopiedToClipboard()
            })
        },
        watch:{
            forumProp(){
                this.fetchPost()
                this.fetchSavedCommentsIDs()
                this.fetchPostSaveStatus()
            }
            // post(newVal, oldVal){
            //     console.log(newVal, oldVal)
            // }
        },
        computed: {
            ...mapGetters(['getUsername']),
            postLink(){
                return `https://jiruto.com/${this.communityOwner}/${this.communityName}/discussions/posts/${this.postID}`
            },
            savedCommentsIDs(){
                return this.$store.state.community.discussions.savedCommentsIDs
            },
            isOwner(){
                if (this.post.creator === null) {
                    return false
                } else {
                    return this.post.creator.username === this.$store.getters.getUsername
                }
            },
            canEdit(){
                return this.isOwner || (this.communityOwner === this.getUsername)
            },
            numberOfComments(){
                if (this.post.commentsCount === 1) {
                    return "1 Comment"
                } else {
                    return `${this.post.commentsCount} Comments`
                }
            },
            commentsByDate(){
                return _.orderBy(this.post.comments, ['createdAt'], ['asc'])
            },
            communityNameForUrl(){
                return this.communityName.replace(/\s/g, "-")
            },
        },
        methods: {
            timeAgo,
            linkCopiedToClipboard(){
                this.showSuccessLinkCopy = true
                setTimeout(() => this.showSuccessLinkCopy = false, 4000)
            },
            // submitComment(commentID, content){
            //     this.submitCommentLocally(commentID, content)
            // },
            submitCommentLocally(commentID, content){
                const commentIndex = _.findIndex(this.post.comments, (comment) => {
                    return comment.JID === commentID
                });
                let comment = this.post.comments.splice(commentIndex, 1)[0]
                comment.content = content
                comment.lastUpdated = new Date().toISOString()
                this.post.comments.splice(commentIndex,0, comment)
            },
            deletePost(){
                if(!this.canEdit) {
                    return
                }
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                this.showDeletePostModal = false
                jigo.delete(`/v2/community/integrations/forum/deletePost/${this.forumProp.JID}/${this.postID}`)
                    .then((resp) => {
                        if(resp.status === 200) {
                            this.$router.push({name: "forumHome"})
                        } else {
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        console.error("ERROR: deletePost FAILED", err)
                    })
            },
            cancelDeletePost(){
                this.showDeletePostModal = false
            },
            editClicked(){
                if(this.isEditingPost === true) {
                    this.cancelPostEdit()
                } else {
                    this.isEditingPost = true
                    this.newPostTitle = this.post.title
                }
            },
            shareClicked(){

            },
            saveClicked(){
                this.isPostSaved ? this.unSavePost() : this.savePost()
            },
            savePost(){
                this.isPostSaved = true
                jigo.put(`/v2/community/integrations/forum/savePost/${this.forumProp.JID}/${this.postID}`)
                    .then((resp) => {
                        if(resp.status !== 200) {
                            this.isPostSaved = false
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                    })
                    .catch((err) => {
                        this.isPostSaved = false
                        console.error("ERROR: savePost FAILED", err)
                    })

            },
            unSavePost(){
                this.isPostSaved = false
                jigo.delete(`/v2/community/integrations/forum/savePost/${this.forumProp.JID}/${this.postID}`)
                    .then((resp) => {
                        if(resp.status !== 200) {
                            this.isPostSaved = true
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                    })
                    .catch((err) => {
                        this.isPostSaved = true
                        console.error("ERROR: unSavePost FAILED", err)
                    })
            },
            deleteClicked(){
                this.showDeletePostModal = true
            },
            cancelCommentEdit(){

            },
            deleteCommentFromDB(commentID){
                return new Promise((resolve, reject) => {
                    jigo.delete(`/v2/community/integrations/forum/deleteComment/${this.forumProp.JID}/${this.postID}/${commentID}`)
                        .then((resp) => {
                            if(resp.status !== 200) {
                                reject()
                            }
                            resolve()
                        })
                        .catch((err) => {
                            console.error("ERROR: deleteComment FAILED", err)
                            reject(err)
                        })
                })
            },
            keepEditing(){
                this.showAlertModal = false
            },
            cancelPostEdit(){
                this.isEditingPost = false
            },
            deleteComment(commentID){
                this.showAlertModal = false
                const commentIndex = _.findIndex(this.post.comments, (comment) => {
                    return comment.JID === commentID
                });
                const comment = this.post.comments.splice(commentIndex, 1)[0]
                this.deleteCommentFromDB(commentID)
                    .then(() => {
                    })
                    .catch(() => {
                        this.post.comments.splice(commentIndex, 0, comment)
                    })
            },
            cancelEditModalApprove(){
                this.showAlertModal = false
                this.isEditingPost = false
            },
            savePostEdit(content){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                let postRequest = {
                    title: this.newPostTitle,
                    content: content
                };
                jigo.post(`/v2/community/integrations/forum/editPost/${this.forumProp.JID}/${this.postID}`, postRequest)
                    .then((resp) => {
                        if (resp.status === 201) {
                            this.post.title = postRequest.title
                            this.post.content = postRequest.content
                            this.post.lastUpdated = new Date().toISOString()
                            this.isEditingPost = false
                        }
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        console.error("ERROR: Submitting post edit FAILED", err)
                    })
            },
            commentPressed(content){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                let commentRequest = {
                    content: content,
                };
                jigo.post(`/v2/community/integrations/forum/newComment/${this.forumProp.JID}/${this.postID}`, commentRequest)
                    .then((resp) => {
                        if (resp.status === 201) {
                            let comment = resp.data
                            this.scrollTo = comment.JID
                            this.post.comments.splice(0, 0, comment)
                            this.$refs.jitapMainCommentEditor.clearContent()
                        }
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        console.error("ERROR: Submitting comment FAILED", err)
                    })
            },
            fetchPost(){
                let loader = this.$loading.show({
                    onCancel: this.onLoaderCancel,
                });
                jigo.get(`/v2/community/integrations/forum/getPost/${this.forumProp.JID}/${this.postID}`)
                    .then((resp) => {
                        if (resp.status === 200) {
                            let post = resp.data
                            // this.$set(this.post, 'content', post.content)
                            if(this.post.comments === null){
                                post.comments = []
                            } else {
                                post.comments = _.orderBy(post.comments, ['createdAt'], ['asc'])
                            }
                            this.post = _.cloneDeep(post)
                        }
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        console.error("ERROR: couldn't fetch post", err)
                    })
            },
            fetchSavedCommentsIDs(){
                jigo.get(`/v2/community/integrations/forum/getSavedCommentsIDs/${this.forumProp.JID}/${this.postID}`)
                    .then((resp) => {
                        if (resp.status === 200) {
                            console.log("Comment IDs:", resp.data)
                            this.$store.dispatch('setSavedCommentsIDs', resp.data)
                        }
                    })
                    .catch((err) => {
                        console.error("ERROR: couldn't fetch comments IDs", err)
                    })
            },
            fetchPostSaveStatus(){
                jigo.get(`/v2/community/integrations/forum/isPostSaved/${this.forumProp.JID}/${this.postID}`)
                    .then((resp) => {
                        if (resp.status === 200) {
                            this.isPostSaved = resp.data
                        }
                    })
                    .catch((err) => {
                        console.error("ERROR: couldn't fetch post save status", err)
                    })
            }
        }
    }
</script>

<style scoped>

    /*.comments-enter-active, .list-leave-active {*/
    /*    transition: all 1s;*/
    /*}*/
    /*.comments-enter, .list-leave-to !* .list-leave-active below version 2.1.8 *! {*/
    /*    opacity: 0;*/
    /*    transform: translateY(30px);*/
    /*}*/
</style>
