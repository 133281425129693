<template>
    <div class="">
        <editor-content class="jitapEditor jitapEditor__content font-light" :editor="editor" />
    </div>

</template>

<script>
    import css from 'highlight.js/lib/languages/css'
    import javascript from 'highlight.js/lib/languages/javascript'
    import python from 'highlight.js/lib/languages/python'
    import java from 'highlight.js/lib/languages/java'
    import go from 'highlight.js/lib/languages/go'
    // import Icon from '../components/Icon'
    import { Editor, EditorContent } from 'tiptap'
    import {
        Blockquote,
        CodeBlock,
        HardBreak,
        Heading,
        HorizontalRule,
        OrderedList,
        BulletList,
        ListItem,
        TodoItem,
        TodoList,
        Bold,
        Table,
        TableCell,
        TableHeader,
        TableRow,
        Code,
        Italic,
        Link,
        Strike,
        Underline,
        History,
        CodeBlockHighlight
    } from 'tiptap-extensions'

    export default {
        name: "JitapCleanEditor",
        props: ['content'],
        components: {
            EditorContent,
            // Icon,
        },
        data() {
            return {
                focused: false,
                editor: new Editor({
                    editable: false,
                    onFocus: this.onFocus,
                    onBlur: this.onBlur,
                    extensions: [
                        new Blockquote(),
                        new BulletList(),
                        new CodeBlock(),
                        new HardBreak(),
                        new Heading({ levels: [1, 2, 3] }),
                        new HorizontalRule(),
                        new ListItem(),
                        new OrderedList(),
                        new TodoItem(),
                        new TodoList(),
                        new Link(),
                        new Bold(),
                        new Code(),
                        new Italic(),
                        new Strike(),
                        new Underline(),
                        new History(),
                        new CodeBlockHighlight({
                            languages: {
                                javascript,
                                css,
                                python,
                                java,
                                go
                            },
                        }),
                        new Table({
                            resizable: true,
                        }),
                        new TableHeader(),
                        new TableCell(),
                        new TableRow(),
                    ],
                    content: this.content,
                }),
            }
        },
        created(){

        },
        methods: {
            onFocus() {
                this.focused = true
            },
            onBlur() {
                this.focused = false
            },
            getHTML(){
                return this.editor.getHTML()
            }
        },
        beforeDestroy() {
            this.editor.destroy()
        },
    }
</script>

<style scoped>

</style>
