<template>
    <div class="editor jitapEditor bg-white rounded-md border border-gray-400 flex flex-col" :class="{'border-indigo-400': focused}">
        <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }"
                         class="bg-opacity-100 z-50 xl:h-10 rounded-md flex px-2 top-0 items-center"
                         :class="{'order-2 bottomSticky bg-gray-400': commentReply, 'topSticky bg-jiruto-zotDarker': !commentReply}">
            <div class="flex justify-evenly flex-wrap p-1 m-1">

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        :class="{ 'jitapButtonActive': isActive.bold()}"
                        @click="commands.bold"
                >

                    <i class="material-icons">format_bold</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        :class="{ 'jitapButtonActive': isActive.italic() }"
                        @click="commands.italic"
                >
                    <i class="material-icons">format_italic</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        :class="{ 'jitapButtonActive': isActive.strike() }"
                        @click="commands.strike"
                >
<!--                    <icon name="strike" />-->
                    <i class="material-icons">strikethrough_s</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        :class="{ 'jitapButtonActive': isActive.underline() }"
                        @click="commands.underline"
                >
                    <i class="material-icons">format_underlined</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        :class="{ 'jitapButtonActive': isActive.code() }"
                        @click="commands.code"
                >
                    <i class="material-icons">code</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        :class="{ 'jitapButtonActive': isActive.paragraph() }"
                        @click="commands.paragraph"
                >
                    <i class="material-icons">format_clear</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        :class="{ 'jitapButtonActive': isActive.heading({ level: 1 }) }"
                        @click="commands.heading({ level: 1 })"
                >
                    <i class="material-icons">format_size</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        :class="{ 'jitapButtonActive': isActive.bullet_list() }"
                        @click="commands.bullet_list"
                >
                    <i class="material-icons">format_list_bulleted</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        :class="{ 'jitapButtonActive': isActive.ordered_list() }"
                        @click="commands.ordered_list"
                >
                    <i class="material-icons">format_list_numbered</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        :class="{ 'jitapButtonActive': isActive.blockquote() }"
                        @click="commands.blockquote"
                >
                    <i class="material-icons">format_quote</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        :class="{ 'jitapButtonActive': isActive.code_block() }"
                        @click="commands.code_block"
                >
                    <i class="material-icons">border_outer</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        @click="commands.horizontal_rule"
                >
                    <i class="material-icons">horizontal_rule</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        @click="commands.createTable({rowsCount: 3, colsCount: 3, withHeaderRow: false })"
                >
                    <i class="material-icons">table_chart</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        @click="commands.undo"
                >
                    <i class="material-icons">undo</i>
                </button>

                <button
                        class="jitapMenuButton hover:bg-jiruto-zotSecondary rounded-md"
                        @click="commands.redo"
                >
                    <i class="material-icons">redo</i>
                </button>

            </div>
        </editor-menu-bar>

        <div @click="focusEditor" class="px-4 py-2 my-2 rounded-md bg-white rounded-md" :class="{'order-1': commentReply}">
            <editor-content class="jitapEditor jitapEditingEditor jitapEditor__content" :editor="editor"
                            :style="{minHeight: minHeight }" />
        </div>

    </div>
</template>

<script>
    import css from 'highlight.js/lib/languages/css'
    import javascript from 'highlight.js/lib/languages/javascript'
    import python from 'highlight.js/lib/languages/python'
    import java from 'highlight.js/lib/languages/java'
    import go from 'highlight.js/lib/languages/go'
    // import Icon from '../components/Icon'
    import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
    import {
        Blockquote,
        CodeBlock,
        HardBreak,
        Heading,
        HorizontalRule,
        OrderedList,
        BulletList,
        ListItem,
        TodoItem,
        TodoList,
        Bold,
        Table,
        TableCell,
        TableHeader,
        TableRow,
        Code,
        Italic,
        Link,
        Strike,
        Underline,
        History,
        CodeBlockHighlight
    } from 'tiptap-extensions'

    export default {
        name: "JitapEditor",
        props:{
            shouldFocus: {
                defaul: false,
            },
            content: {
                default: "<p></p>"
            },
            commentReply: {
                default: false,
            }
        },
        components: {
            EditorContent,
            EditorMenuBar,
            // Icon,
        },
        data() {
            return {
                focused: false,
                editor: new Editor({
                    onFocus: this.onFocus,
                    onBlur: this.onBlur,
                    preserveWhiteSpace: true,
                    parserOptions: {
                        preserveWhiteSpace: true,
                    },
                    parseOptions: {
                        preserveWhiteSpace: true,
                    },
                    // options: {
                    //     // perserveWhiteSpaces: true,
                    //     preserveWhiteSpace: 'true'
                    // },
                    extensions: [
                        new Blockquote(),
                        new BulletList(),
                        new CodeBlock(),
                        new HardBreak(),
                        new Heading({ levels: [1, 2, 3] }),
                        new HorizontalRule(),
                        new ListItem(),
                        new OrderedList(),
                        new TodoItem(),
                        new TodoList(),
                        new Link(),
                        new Bold(),
                        new Code(),
                        new Italic(),
                        new Strike(),
                        new Underline(),
                        new History(),
                        new CodeBlockHighlight({
                            languages: {
                                javascript,
                                css,
                                python,
                                java,
                                go
                            },
                        }),
                        new Table({
                            resizable: true,
                        }),
                        new TableHeader(),
                        new TableCell(),
                        new TableRow(),
                    ],
                    content: '',
        //             content: `
        //   </br>
        //   </br>
        //   </br>
        //   </br>
        //   </br>
        //   </br>
        // `,
                }),
            }
        },
        watch: {
            content(){
                console.log("Content has changed!!!")
                if (this.content != null) {
                    this.editor.setContent(this.content)
                }
            }
        },
        computed: {
            minHeight(){
                return this.commentReply ? "100px" : "150px"
            }
        },
        created(){
            if (this.content != null) {
                this.editor.setContent(this.content)
            }
            window.onbeforeunload = () => {
                if(this.content !== this.editor.getHTML()) {
                    // console.log("CLOSING!! but there are unsaved changes");
                    // console.log("HTML", this.editor.getHTML());
                    // console.log("Content", this.content);
                    return 'You are currently Editing. Do you want to take this action?'
                }
            }
            if(this.shouldFocus){
                this.focus()
            }

        },
        methods: {
            focus(position){
                this.editor.focus(position)
            },
            onFocus() {
                this.focused = true
            },
            clearContent(){
                this.editor.clearContent()
            },
            onBlur() {
                this.focused = false
            },
            getHTML(){
                return this.editor.getHTML()
            },
            focusEditor(){
                this.editor.focus()
            }
        },
        beforeDestroy() {
            this.editor.destroy()
            window.onbeforeunload = {}
        },
    }
</script>

<style >
    .jitapButtonActive {
        @apply bg-white rounded-md text-jiruto-zotDarker !important
    }

    .topSticky {
        position: -webkit-sticky !important;
        position: sticky !important;
        top: 3px;
    }

    .bottomSticky {
        position: -webkit-sticky !important;
        position: sticky !important;
        bottom: 3px;
    }

    .jitapMenuButton {
        @apply py-1 px-2 mx-1 flex items-center justify-center text-white
    }




</style>

<style lang="scss">
    @import 'src/assets/styles/tiptap/variables';
    //.jitapEditingEditor {
    //    min-height: 150px;
    //
    //}

    .jitapEditor {

        :focus {
            outline: 0
        }

        p {
            margin-top: 8px;
            margin-bottom: 8px;
        }

        h1, h2, h3 {
            font-size: 30px;
        }

        &__content {

            :focus {
                outline: 0;
            }

            overflow-wrap: break-word;
            word-wrap: break-word;
            word-break: break-word;

            * {
                caret-color: currentColor;
            }

            pre {
                padding: 0.7rem 1rem;
                border-radius: 5px;
                background: $color-grey;
                color: #2c3e50;
                font-size: 0.8rem;
                overflow-x: auto;
                margin-top: 10px;
                /*white-space: nowrap;*/

                &::before {
                    content: attr(data-language);
                    text-transform: uppercase;
                    display: block;
                    text-align: right;
                    font-weight: bold;
                    font-size: 0.6rem;
                }

                code {
                    overflow-x: auto;
                    color: #2c3e50;

                    .hljs-comment,
                    .hljs-quote {
                        color: #999999;
                    }
                    .hljs-variable,
                    .hljs-template-variable,
                    .hljs-attribute,
                    .hljs-tag,
                    .hljs-name,
                    .hljs-regexp,
                    .hljs-link,
                    .hljs-name,
                    .hljs-selector-id,
                    .hljs-selector-class {
                        color: #f2777a;
                    }
                    .hljs-number,
                    .hljs-meta,
                    .hljs-built_in,
                    .hljs-builtin-name,
                    .hljs-literal,
                    .hljs-type,
                    .hljs-params {
                        color: #f99157;
                    }
                    .hljs-string,
                    .hljs-symbol,
                    .hljs-bullet {
                        color: #99cc99;
                    }
                    .hljs-title,
                    .hljs-section {
                        color: #ffcc66;
                    }
                    .hljs-keyword,
                    .hljs-selector-tag {
                        color: #6699cc;
                    }
                    .hljs-emphasis {
                        font-style: italic;
                    }
                    .hljs-strong {
                        font-weight: 700;
                    }
                }
            }

            p code {
                display: flex;
                padding: 0.2rem 0.4rem;
                border-radius: 5px;
                font-size: 0.8rem;
                font-weight: bold;
                background: rgba($color-grey, 0.1);
                overflow-x: scroll;
                color: #2c3e50;
                /*margin-top: 10px;*/
            }

            ul,
            ol {
                padding-left: 1rem;
            }

            li > p,
            li > ol,
            li > ul {
                margin: 0;
            }

            a {
                color: inherit;
            }

            blockquote {
                border-left: 3px solid rgba($color-black, 0.1);
                color: rgba($color-black, 0.8);
                padding-left: 0.8rem;
                font-style: italic;

                p {
                    margin: 0;
                    /*margin-top: 10px;*/
                }
            }

            img {
                max-width: 100%;
                border-radius: 3px;
            }

            table {
                border-collapse: collapse;
                table-layout: fixed;
                width: 100%;
                margin: 0;
                overflow: hidden;

                td, th {
                    min-width: 1em;
                    border: 2px solid $color-grey;
                    padding: 3px 5px;
                    vertical-align: top;
                    box-sizing: border-box;
                    position: relative;
                    > * {
                        margin-bottom: 0;
                    }
                }

                th {
                    font-weight: bold;
                    text-align: left;
                }

                .selectedCell:after {
                    z-index: 2;
                    position: absolute;
                    content: "";
                    left: 0; right: 0; top: 0; bottom: 0;
                    background: rgba(200, 200, 255, 0.4);
                    pointer-events: none;
                }

                .column-resize-handle {
                    position: absolute;
                    right: -2px; top: 0; bottom: 0;
                    width: 4px;
                    z-index: 20;
                    background-color: #adf;
                    pointer-events: none;
                }
            }

            .tableWrapper {
                margin: 1em 0;
                overflow-x: auto;
            }

            .resize-cursor {
                cursor: ew-resize;
                cursor: col-resize;
            }

        }
    }

</style>

