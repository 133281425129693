<template>
    <div v-if="comment !== null " class="" :class="{'mt-10':!commentToComment, 'my-1':commentToComment}">
        <div class="bg-white rounded-lg" :class="{'shadow':!commentToComment, 'bg-gray-200': commentToComment}">
            <div class="border-b border-gray-200 px-4 py-1 sm:px-1" :class="{'': commentToComment}">
                <div class="flex justify-between">
                    <div>
                        <button @click="goToProfile" class="max-w-xs flex items-center text-md rounded-full focus:outline-none lg:p-2 lg:rounded-md" id="user-menu" aria-label="User menu" aria-haspopup="true">
<!--                            <img class="h-8 w-8 rounded-full bg-gray-400" :src="comment.creator[0].avatarUrl" alt="">-->
<!--                            <user-pop-card :prop-user="comment.creator[0]"></user-pop-card>-->
                            <profile-image-with-pop-card :prop-user="comment.creator"></profile-image-with-pop-card>
                            <span class="hidden ml-3 text-cool-gray-700 text-sm leading-5 font-bold lg:block">
                                {{ comment.creator.firstName + " " + comment.creator.lastName }}
                            </span>
                            <span class="text-cool-gray-500 text-sm ml-1">@{{comment.creator.username}}</span>
                        </button>
                    </div>
                    <div class="px-6 py-4 whitespace-no-wrap text-right text-sm leading-5 text-gray-500">
                        {{ timeAgo(comment.createdAt) }}
                    </div>
                </div>
                <div class="w-full flex items-center text-md rounded-full focus:outline-none lg:px-2 lg:rounded-md mb-" >
                    <jitap-clean-editor v-if="!isEditing" :content="comment.content"></jitap-clean-editor>
                    <main-editor-component :should-focus="true" :comment-reply="true" :ref="`existing-${comment.JID}`" :content="comment.content" v-else @save="submitComment" @cancel="cancelEdit"/>
                </div>
            </div>

            <div class="mx-1 ml-10">
                <transition-group name="comments" tag="div"
                                  enter-class="transform opacity-0 scale-y-95"
                                  enter-active-class="transition ease-out duration-700"
                                  enter-to-class="transform opacity-100 scale-y-100"
                                  leave-class="transform opacity-100 scale-y-100"
                                  leave-active-class="transition ease-in duration-700"
                                  leave-to-class="transform opacity-0 scale-y-95"
                >
                    <div  v-for="(comment) in commentsByDate" :key="comment.JID">
                        <div :id="`comment-${comment.JID}`">
                            <div>
                                <forum-comment :parentCommentID="comment.JID" :comment-to-comment="true" :communityOwner="communityOwner" :communityName="communityName" :postID="postID" :comment-prop="comment" @deleteComment="deleteFromComments" />
                            </div>
                        </div>
                    </div>
                </transition-group>
            </div>

            <div v-if="!commentToComment" class="border-b border-gray-200 bg-gray-100 px-4 py-1 sm:px-1 flex" >

                <div class="p-2">
                    <img class="inline-block h-6 w-6 rounded-full" :src="$store.getters.getAvatarUrl" alt="">
                </div>

                <div class="px-2 py-5 sm:p-2 flex-grow" v-if="isReplying">
                    <div>
                        <jitap-clean-editor v-if="shouldPreviewReply" :content="commentReply.content"/>
                        <main-editor-component :ref="`new-${comment.JID}`" :shouldFocus="true" :comment-reply="true" v-else @save="commentPressed" @cancel="cancelReply"/>
                    </div>
                </div>

                <div v-else class="flex-grow w-full">
                    <div class="bg-gray-100 rounded-md p-1 flex-grow">
                        <div>
                            <label :for="`reply${comment.JID}`" class="sr-only">Reply</label>
                            <div class="relative rounded-md shadow-sm">
                                <input @focus="replyToComment" :id="`reply${comment.JID}`" class="form-input h-8 border-gray-500 bg-white hover:bg-white hover:border-indigo-600 block w-full sm:text-sm sm:leading-5 placeholder-gray-500 px-2" placeholder="Reply">
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="px-4 py-5 sm:p-1 flex items-center flex justify-between" >
                <div class="flex">
                    <button v-if="canEdit" @click="editClicked" class="text-cool-gray-500 flex items-center py-1 px-2 rounded-md hover:bg-purple-200">
                        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"></path>
                        </svg>
                        <span class="ml-1 text-sm">Edit</span>
                    </button>

                    <button :data-clipboard-text="commentLink" :id="buttonID" class="text-cool-gray-500 flex items-center py-1 px-2 rounded-md hover:bg-purple-200 ml-1" :class="buttonID">
                        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"/>
                        </svg>
                        <span class="ml-1 text-sm">Share</span>
                    </button>

                    <button @click="saveClicked" class="text-cool-gray-500 flex items-center py-1 px-2 rounded-md hover:bg-purple-200 ml-1" :class="{'text-jiruto-zotSecondary': isSaved}">
                        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"/>
                        </svg>
                        <span class="ml-1 text-sm">
                            {{isSaved ? "Saved" : "Save"}}
                        </span>
                    </button>
                </div>
                <div>
                    <button v-if="canEdit" @click="deleteClicked" class="text-cool-gray-500 flex items-center py-1 px-2 rounded-md hover:bg-red-500 hover:text-white ml-1">
                        <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"/>
                        </svg>
                        <span class="ml-1 text-sm">Delete</span>
                    </button>
                </div>
            </div>
        </div>


        <alert-modal :open="showDeleteModal" v-on:close="cancelDelete" v-on:reset="deleteComment">
            <template v-slot:header>
                This will delete your comment!
            </template>
            <template v-slot:text>
                You've requested to delete this comment. Please confirm if you meant to take this action.
            </template>
            <template v-slot:button>
                Delete
            </template>
            <template v-slot:cancelButton>
                Cancel
            </template>
        </alert-modal>

        <successNotification @closeNotification="showSuccessLinkCopy = false"  :open="showSuccessLinkCopy" class="z-100" style="z-index: 100">
            <template v-slot:header>
                Link Copied!
            </template>
            <template v-slot:text>
                Comment link copied to your clipboard successfully.
            </template>
        </successNotification>
    </div>
</template>

<script>
    import {timeAgo} from "@/utils/helpers";
    import * as _ from 'lodash'
    import {jigo} from '@/axios-auth'
    import {mapGetters} from 'vuex'
    import clipboard from 'clipboard'
    import JitapCleanEditor from "../../../components/JitapCleanEditor";
    import MainEditorComponent from "../../../components/MainEditorComponent";
    import AlertModal from "../../../components/AlertModal";
    import SuccessNotification from "../../../components/ActionNotification";
    // import UserPopCard from "@/components/UserPopCard";
    import ProfileImageWithPopCard from "@/components/ProfileImageWithPopCard";
    // import ShareDropDownMenu from "../../../components/ShareDropDownMenu";
    export default {
        name: "ForumComment",
        components: {
            ProfileImageWithPopCard,
            // UserPopCard,
            SuccessNotification,
            AlertModal, MainEditorComponent, JitapCleanEditor},
        props: {
            commentProp: {
                content: {},
                comments: {},
                createdAt: {},
            },
            // forumProp: null,
            forumID: null,
            communityOwner: {},
            communityName: {},
            postID: {},
            parentCommentID: {},
            commentToComment: {
                default: false,
            }
        },
        data(){
            return{
                comment: {
                    content: {},
                    comments: {},
                    createdAt: {},
                },
                newCommentRef: null,
                isReplying: false,
                shouldPreviewReply: false,
                commentReply: "",
                clipboard: null,
                reply: false,
                isEditing: false,
                showDeleteModal: false,
                showSuccessLinkCopy: false,
            }
        },
        created(){
            this.comment = _.cloneDeep(this.commentProp)
            this.newCommentRef = `new-${this.comment.JID}`
            if(this.comment.comments === null) {
                this.comment.comments = []
            }
        },
        mounted(){
            this.clipboard =  new clipboard(`#${this.buttonID}`)
            this.clipboard.on('success', () => {
                this.linkCopiedToClipboard()
            })
            this.$emit("mounted", this.comment.JID)
        },
        watch: {
            commentProp(){
                this.comment = _.cloneDeep(this.commentProp)
                if(this.comment.comments === null) {
                    this.comment.comments = []
                }
            }
        },
        methods: {
            timeAgo,

            // Dedicated for handling inner comments (sub comments)

            deleteCommentFromDB(commentID){
                return new Promise((resolve, reject) => {
                    jigo.delete(`/v2/community/integrations/forum/deleteComment/${this.forumID}/${this.postID}/${commentID}`)
                    .then((resp) => {
                        if(resp.status !== 200) {
                            reject()
                        }
                        resolve()
                    })
                    .catch((err) => {
                        console.error("ERROR: deleteComment FAILED", err)
                        reject(err)
                    })
                })
            },

            deleteFromComments(commentID){
                console.log("I'm here! in delete from comments!")
                this.showAlertModal = false
                const commentIndex = _.findIndex(this.comment.comments, (comment) => {
                    return comment.JID === commentID
                });
                const comment = this.comment.comments.splice(commentIndex, 1)[0]
                this.deleteCommentFromDB(commentID)
                .then(() => {
                    console.log("This is a success!")
                })
                .catch(() => {
                    console.log("Obviously fails!")
                    this.comment.comments.splice(commentIndex, 0, comment)
                })
            },
            addToComments(newComment){
                this.comment.comments.splice(0,0, newComment)
            },

            commentPressed(content){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                let commentRequest = {
                    content: content,
                };
                jigo.post(`/v2/community/integrations/forum/newCommentToComment/${this.forumID}/${this.postID}/${this.comment.JID}`, commentRequest)
                .then((resp) => {
                    if (resp.status === 201) {
                        let comment = resp.data
                        this.scrollTo = comment.JID
                        this.comment.comments.splice(this.comment.comments.length , 0, comment)
                        this.$refs[`new-${this.comment.JID}`].clearContent()
                        this.isReplying = false
                    }
                    loader.hide()
                })
                .catch((err) => {
                    loader.hide()
                    console.error("ERROR: Submitting comment FAILED", err)
                })
            },
            // commentPressed(content){
            //     let loader = this.$loading.show({
            //         onCancel: this.loaderCanceled,
            //     });
            //     const commentRequest = {
            //         content: content
            //     }
            //     jigo.post(`/v2/community/integrations/forum/newCommentToComment/${this.forumID}/${this.postID}/${this.comment.JID}`, commentRequest)
            //     .then((resp) => {
            //         if(resp.status === 201) {
            //             const newComment = resp.data
            //             this.$refs[this.comment.JID].clearContent()
            //             this.$emit("commentToCommentAdded", newComment)
            //             this.isReplying = false
            //         } else {
            //             console.error("ERROR: unwanted status returned", resp.status)
            //         }
            //         loader.hide()
            //     })
            //     .catch((err) => {
            //         loader.hide()
            //         console.error("ERROR: submitComment FAILED", err)
            //     })
            // },


            //Relevant to All
            cancelReply(){
                this.isReplying = false
            },
            postReply(){
            },
            replyToComment(){
                this.isReplying = true
                // console.log("reply to comment presesd",this.newCommentRef, this.$refs[this.newCommentRef])
                // this.$refs[this.newCommentRef].focus()

            },
            linkCopiedToClipboard(){
                this.showSuccessLinkCopy = true
                setTimeout(() => this.showSuccessLinkCopy = false, 4000)
            },
            submitComment(content){
                let loader = this.$loading.show({
                    onCancel: this.loaderCanceled,
                });
                const comment = {
                    content: content
                }
                jigo.post(`/v2/community/integrations/forum/editComment/${this.forumID}/${this.postID}/${this.comment.JID}`, comment)
                    .then((resp) => {
                        if(resp.status === 200  ) {
                            this.isEditing = false
                            this.comment.content = content
                            this.lastUpdate = new Date().toISOString()
                            // this.$emit("submitComment", this.comment.JID, content)
                        } else {
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                        loader.hide()
                    })
                    .catch((err) => {
                        loader.hide()
                        console.error("ERROR: submitComment FAILED", err)
                    })
            },
            saveEdit(content){
                console.log(content)
                this.isEditing = false
                this.$emit("saveComment", this.forumID, content)
            },
            cancelEdit(){
                this.isEditing = false
            },
            goToProfile(){
                const destination = `/${this.$store.getters.getUsername}`
                if(this.$router.currentRoute.path !== destination){
                    this.$router.push(destination)
                }
            },
            editClicked(){
                if(this.isEditing === true) {
                    this.cancelEdit()
                } else {
                    this.isEditing = true
                }
                this.isEditing = true
            },
            shareClicked(){

            },

            // Adding the comment to "my saved comments". This is NOT for submitting or editing a comment
            saveClicked(){
                this.isSaved ? this.unSaveComment() : this.saveComment()
            },
            // Adding the comment to "my saved comments". This is NOT for submitting or editing a comment
            saveComment(){
                this.saveCommentLocally()
                jigo.put(`/v2/community/integrations/forum/saveComment/${this.forumID}/${this.postID}/${this.comment.JID}`)
                    .then((resp) => {
                        if(resp.status !== 200) {
                            this.unSaveCommentLocally();
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                    })
                    .catch((err) => {
                        this.unSaveCommentLocally();
                        console.error("ERROR: saveComment FAILED", err)
                    })
            },
            unSaveComment(){
                this.unSaveCommentLocally();
                jigo.delete(`/v2/community/integrations/forum/saveComment/${this.forumID}/${this.postID}/${this.comment.JID}`)
                    .then((resp) => {
                        if(resp.status !== 200) {
                            this.saveCommentLocally();
                            console.error("ERROR: unwanted status returned", resp.status)
                        }
                    })
                    .catch((err) => {
                        this.saveCommentLocally();
                        console.error("ERROR: unSaveComment FAILED", err)
                    })
            },
            saveCommentLocally(){
                this.$store.dispatch('addSavedCommentID', this.comment.JID)
            },
            unSaveCommentLocally(){
                this.$store.dispatch('removeSavedCommentID', this.comment.JID)
            },
            deleteClicked(){
                this.showDeleteModal = true
            },
            cancelEditPressed(){

            },
            replyClicked(){
                this.$store.dispatch("getCommentByID", this.comment.JID)
                .then((comment) => {

                    console.log("The comment is!", comment)
                }).catch((err) => {
                    console.error("ERRoR: getCommentByID FAILED", err)
                })
            },
            deleteComment(){
                this.showDeleteModal = false
                setTimeout(() => {
                    this.$emit('deleteComment', this.comment.JID)
                },150)
            },
            cancelDelete(){
                this.showDeleteModal = false
            }
        },
        computed: {
            ...mapGetters(['getAvatarUrl', 'getUsername']),
            commentsByDate(){
                return _.orderBy(this.comment.comments, ['createdAt'], ['asc'])
            },
            buttonID(){
                return `copyLink-${this.comment.JID}`
            },
            commentLink(){
                return `https://jiruto.com/${this.communityOwner}/${this.communityNameForUrl}/discussions/posts/${this.postID}#comment-${this.comment.JID}`
            },
            savedCommentsIDs(){
                return this.$store.state.community.discussions.savedCommentsIDs
            },
            isSaved(){
                if (this.savedCommentsIDs !== null){
                    return this.savedCommentsIDs.includes(this.comment.JID)
                } else {
                    return false
                }
            },
            isCommentOwner(){
                return this.comment.creator.username === this.$store.getters.getUsername
            },
            canEdit(){
                return this.isCommentOwner || (this.communityOwner === this.getUsername)
            },
            communityNameForUrl(){
                return this.communityName.replace(/\s/g, "-")
            },
        },
        beforeDestroy() {
            this.showDeleteModal = false
        },
    }
</script>

<style scoped>

</style>




// updateComment(commentID, content){
//     const commentIndex = _.findIndex(this.comment.comments, (comment) => {
//         return comment.JID === commentID
//     });
//     // this.comment.comments[commentIndex].content = content
//     // this.comment.comments[commentIndex].lastUpdated = new Date().toISOString()
//
//     let comment = this.comment.comments.splice(commentIndex, 1)[0]
//     comment.content = content
//     comment.lastUpdated =
//     console.log("New comment to be saved:", comment.content)
//     this.comment.comments.splice(commentIndex, 0, comment)
// },
//Submit an update
