<template>
    <div class="w-full">
        <div>
            <jitap-editor :shouldFocus="shouldFocus" :comment-reply="commentReply" :content="content" :ref="ref"/>
        </div>
        <div class="flex justify-end mt-2">
            <div v-if="commentReply">
                <button @click="cancelEdit" type="button" class="mr-2 inline-flex items-center px-6 py-2 border border-white text-sm leading-4 font-medium rounded-md text-gray-700 hover:text-white border-black border hover:border-jiruto-flatRed hover:bg-jiruto-flatRed focus:outline-none focus:border-red-700 focus:shadow-outline-indigo active:bg-red-500 transition ease-in-out duration-150">
                    Cancel
                </button>
                <button @click="saveEdit" type="button" class="inline-flex items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                    Submit
                </button>
            </div>
            <div v-else>
                <button @click="cancelEdit" type="button" class="mr-2 text-lg inline-flex items-center px-6 py-2 border border-white text-sm leading-4 font-medium rounded-md text-gray-700 hover:text-white border-black border hover:border-jiruto-flatRed hover:bg-jiruto-flatRed focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-red-500 transition ease-in-out duration-150">
                    Cancel
                </button>
                <button @click="saveEdit" type="button" class="text-lg inline-flex items-center px-6 py-2 border border-transparent text-sm leading-4 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150">
                    Submit
                </button>
            </div>
        </div>

        <alert-modal :open="showAlertModal" v-on:close="keepEditing" v-on:reset="cancelApproved" class="z-400">
            <template v-slot:header>
                This will erase your changes!
            </template>
            <template v-slot:text>
                You've requested to cancel. Please confirm if you meant to take this action.
            </template>
            <template v-slot:button>
                Approve
            </template>
            <template v-slot:cancelButton>
                Keep Editing
            </template>
        </alert-modal>
    </div>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    import JitapEditor from "./JitapEditor";
    import AlertModal from "./AlertModal";
    export default {
        name: "MainEditorComponent",
        components: {AlertModal, JitapEditor},
        // props: ['content'],
        props: {
            shouldFocus: {
                default: false,
            },
            content: {
                default: `<p></p>`
            },
            commentReply: {
                default: false,
            }
        },
        data(){
            return {
                showAlertModal: false,
                ref: "ref:" + uuidv4()
            }
        },
        methods: {
            focus(){
                this.$refs[this.ref].focus()
            },
            cancelEdit(){
                const content = this.$refs[this.ref].getHTML()
                if(content === this.content){
                    this.cancelApproved()
                } else {
                    this.showAlertModal = true
                }
            },
            saveEdit(){
                const content = this.$refs[this.ref].getHTML()
                this.$emit("save", content)
            },
            keepEditing(){
                this.showAlertModal = false
            },
            cancelApproved(){
                this.showAlertModal = false
                this.$refs[this.ref].editor.clearContent()
                this.$emit("cancel")
            },
            clearContent(){
                this.$refs[this.ref].editor.clearContent()
            }
        }
    }
</script>

<style scoped>

</style>
